import React from "react"
import { Link } from "gatsby"
import { Grid, Box, Text, Button } from "theme-ui"

const Breadcrumbs = ({ title, headline, parent, ...props }) => {
  return (
    <Grid variant={title === "Role" ? "base" : "textWrap"} columns={["1fr", "1fr 3fr 1fr", "1fr 4fr 1fr"]} {...props}>
      {title === "Role" && <Box />}
      <Box sx={{ textAlign: title === "Role" ? "center" : "left" }}>
        <Text as="h1" variant="button" sx={{ mb: 1 }}>
          {parent && <Link to={parent[1]}><Button variant="tertiary" sx={{ ml: -1 }}>{parent[0]}</Button></Link>}
          {parent && <Text as="span">/&nbsp;&nbsp;</Text>}
          {title}
        </Text>
        <Text as="p" variant="display">{headline}</Text>
      </Box>
      {title === "Role" && <Box />}
    </Grid>
  )
}

export default Breadcrumbs
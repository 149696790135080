import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Box, Grid, Divider, Text, Image } from "theme-ui"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import LinkCard from "../../components/LinkCard"
import NewsArchive from "./NewsArchive"

import muckrakerImage from "../../images/spots/muckraker.png"

const NewsPage = () => {
  const data = useStaticQuery (
    graphql`
      query {
        allContentfulNewsArticle(limit: 3, sort: { fields: createdAt, order: DESC }) {
          edges {
            node {
              headline
              createdAt(formatString: "MMMM D, YYYY")
              slug
              excerpt {
                excerpt
              }
            }
          }
        }
      }
    `
  )
  
  return (
    <Layout>
      <SEO title="News" />

      <Breadcrumbs title="News" />

      <Grid variant="base" columns={[1, 2, 3]} sx={{ gridGap: 6, mt: [3, 4] }}>
        {data.allContentfulNewsArticle.edges.map(edge => {
          return (
            <LinkCard
              label={edge.node.createdAt}
              title={edge.node.headline}
              body={edge.node.excerpt.excerpt}
              buttonLabel="Read More"
              url={`/news/${edge.node.slug}/`} />
          )
        })}
      </Grid>

      <Grid variant="base" columns={[1, "1fr 3fr"]} sx={{ mt: [7, 8] }}>
        <Image src={muckrakerImage} alt="The Muckracker" sx={{ width: "100%", maxWidth: [200, 400] }} />
        
        <Box>
          <Text variant="title03" sx={{ mb: 4 }}>All news</Text>
          <NewsArchive />
        </Box>
      </Grid>

      <Grid variant="base">
        <Divider />
      </Grid>

      <Grid variant="textWrap">
        <Box>
          <Text as="p" variant="button" sx={{ mb: 2 }}>For reporters & professional media</Text>
          <Text as="p" variant="display">Media inquiries</Text>
        </Box>
      </Grid>

      <Grid variant="base" columns={[1, "2fr 1fr"]} gap={[4, 6, 8]}>
        <Text as="p" variant="body">If you're a reporter, product reviewer, or member of the professional media, please contact us at our press line for inquiries. Please note if you are on deadline.</Text>

        <Text as="p" variant="bodyLarge">
          <Text as="a" variant="link" href="mailto:press@adventure.game">press@adventure.game</Text>
        </Text>
      </Grid>
    </Layout>
  )
}

export default NewsPage
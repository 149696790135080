import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import { Box, Flex, Text } from "theme-ui"

const NewsArchive = () => {
  const data = useStaticQuery (
    graphql`
      query {
        allContentfulNewsArticle(sort: { fields: createdAt, order: DESC }) {
          edges {
            node {
              headline
              createdAt(formatString: "MMMM D, YYYY")
              slug
              excerpt {
                excerpt
              }
            }
          }
        }
      }
    `
  )
  
  return (
    <>
      {data.allContentfulNewsArticle.edges.map(edge => {
        return (
          <Link to={`/news/${edge.node.slug}/`}>
            <Flex sx={{ alignItems: "baseline", flexWrap: "wrap", mb: 4 }}>
              <Box sx={{ mr: 3 }}>
                <Text as="p" variant="link" sx={{ display: "inline" }}>{edge.node.headline}</Text>
              </Box>
              <Text as="p" variant="label" sx={{ color: "grayMedium" }}>{edge.node.createdAt}</Text>
            </Flex>
          </Link>
        )
      })}
    </>
  )
}

export default NewsArchive